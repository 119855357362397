<template>
    <div>
        <page-header :title="`Phản ánh đã lưu trữ`" :items="breadcrumbs" />
        <div class="card">
            <div class="card-body">
                <div class="mb-2">
                    <product-filter />
                    <div class="col-mr-3">
                        <div class="text-sm-right">
                        </div>
                    </div>
                </div>
                <div v-show="!loading" class="table-responsive">
                    <table class="table table-hover table-centered table-nowrap">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">Hình ảnh</th>
                                <th scope="col">Danh mục</th>
                                <th scope="col">Người gửi</th>
                                <th scope="col" style="text-align:center">Nội dung</th>
                                <th scope="col">Địa điểm</th>
                                <th scope="col">Thời gian</th>
                                <th scope="col">Phụ trách</th>
                                <th scope="col">Chức vụ</th>
                                <th scope="col">Trạng thái</th>
                                <th scope="col" style="width: 30px;"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="entry in entries" :key="entry.id">
                                <td>
                                    <div v-if="entry.media.length != 0 && (checkTypeFile(entry.thumb) == 'video')">
                                        <video muted class="avatar-md" @error="replaceBrokenImage">
                                            <source :src="addPrefixImage(entry.thumb)">
                                        </video>
                                    </div>
                                    <div v-else>
                                        <img :src="addPrefixImage(entry.thumb)" class="avatar-md"
                                            @error="replaceBrokenImage" />
                                    </div>
                                </td>
                                <td>
                                    {{ entry.theLoai }}
                                </td>
                                <td>
                                    {{ entry.tenNguoiGui ? entry.tenNguoiGui : entry.nguoiGui }}
                                </td>
                                <td>
                                    {{ strippedHtml(entry.noiDung) }}
                                </td>
                                <td>
                                    {{ entry.diaDiem }}
                                </td>
                                <td>
                                    {{ entry.thoiGian | formatDateTime }}
                                </td>
                                <td>
                                    {{ entry.tenPhuTrach }}
                                </td>
                                <td>
                                    {{ entry.position }}
                                </td>
                                <td>
                                    {{ entry.trangThai }}
                                </td>
                                <td class="text-center" style="font-size: 20px">
                                    <router-link v-if="(entry.trangThai == 'Chờ xử lý')" v-b-tooltip.hover
                                        :title="`Chi tiết`" class="text-success"
                                        :to="{ name: 'admin.sentiments.edit', params: { id: entry.id, form: entry } }">
                                        <i class="bx bx-right-arrow-circle"></i>
                                    </router-link>
                                    <router-link v-else-if="(entry.trangThai == 'Đang xử lý')" v-b-tooltip.hover
                                        :title="`Chi tiết`" class="text-success"
                                        :to="{ name: 'admin.sentiments.updateStatus', params: { id: entry.id, form: entry } }">
                                        <i class="bx bx-right-arrow-circle"></i>
                                    </router-link>
                                    <router-link v-else v-b-tooltip.hover :title="`Chi tiết`" class="text-success"
                                        :to="{ name: 'admin.sentiments.result', params: { id: entry.id } }">
                                        <i class="bx bx-right-arrow-circle"></i>
                                    </router-link>
                                    <a href="javascript: void(0);" v-b-tooltip.hover :title="`Khôi phục`"
                                        class="text-primary" @click.prevent="restore(entry)"><i
                                            class="bx bx-analyse"></i></a>
                                    <a v-if="(roleAdmin.includes('admin')) || roleLevel == 3"
                                        href="javascript: void(0);" v-b-tooltip.hover :title="`Xoá`" class="text-danger"
                                        @click.prevent="deleteEntry(entry)"> <i class="bx bx-trash"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- loading -->
                <div v-show="loading" class="text-center mt-5">
                    <b-spinner variant="primary" label="Text Centered"></b-spinner>
                </div>
                <!-- table information-->
                <div style="font-size: 14px;" class="row align-items-center">
                    <div class="col-sm-8 col-xs-6 text-right">
                        <table-info :pagination="pagination" />
                    </div>
                    <div class="col-sm-4 col-xs-6">
                        <table-pagination :pagination="pagination" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageHeader from '../../../components/page-header';
import Swal from 'sweetalert2';
import TableInfo from '../../../components/datatable/table-info';
import TablePagination from '../../../components/datatable/table-pagination';
import ProductFilter from './/partials/product-filter';
import addressFilter from '../../../components/filter/address-filter'
import {
    checkFile
} from './partials/checkTypeFile';
import {
    httpClient
} from "../../../_utils/httpClient";
import {
    httpPreview
} from "../../../_utils/httpPreview";
import {
    Base64
} from 'js-base64'

export default {
    name: 'list',
    components: {
        ProductFilter,
        TablePagination,
        TableInfo,
        PageHeader,
        addressFilter
    },
    data: () => ({
        search: '',
        breadcrumbs: [{
            text: 'Trang chủ',
            to: '/admin/home',
        }, {
            text: 'Phản ánh',
            to: '/admin/sentiments',
        }, {
            text: 'Lưu trữ',
            to: '#',
            active: true,
        }],
        entries: [],
        pagination: {
            limit: 10,
            skip: 0,
            total: 0,
            page: 1,
            search: '',
        },
        userName: "",
        roleAdmin: "",
        roleLevel: "",
        loading: true,
    }),
    watch: {
        '$route.query': {
            handler: function () {
                console.log('$route.query', this.$route.query)
                this.getData((this.$route.query.page - 1) * this.pagination.limit, this.$route.query);
            },
        },
    },
    methods: {
        checkTypeFile(src) {
            return checkFile(src);
        },
        addPrefixImage(src) {
            if (src && src.startsWith(`http`)) return src
            return httpPreview + src
        },
        async getData(skip = 0, payload = {}) {
            this.loading = true
            if (isNaN(skip)) {
                skip = 0;
            }
            this.pagination.skip = skip
            let body = {}
            if (!payload.archived) {
                body = {
                    ...body,
                    "archived": true
                }
            }
            if (payload.category) {
                body = {
                    ...body,
                    "theLoai": payload.category
                }
            }
            if (payload.trangThai) {
                if (payload.trangThai != "Quá hạn xử lý") {
                    body = {
                        ...body,
                        "trangThai": payload.trangThai
                    }
                } else {
                    var today = new Date(Date.now())
                    today.setHours(0, 0, 0, 0)
                    body = {
                        ...body,
                        "$and": [{
                            "thoiGianKetThuc": {
                                "$lt": Date.parse(today),
                                "$ne": 0,
                            }
                        },
                        {
                            "$or": [{
                                "trangThai": "Chờ xử lý"
                            },
                            {
                                "trangThai": "Đang xử lý"
                            },
                            ]
                        }
                        ]
                    }
                }
            }
            if (payload.phuTrach) {
                body = {
                    ...body,
                    "phuTrach": payload.phuTrach
                }
            }
            if (payload.province) {
                body = {
                    ...body,
                    "province": payload.province
                }
            }
            if (payload.district) {
                body = {
                    ...body,
                    "district": payload.district
                }
            }
            if (payload.ward) {
                body = {
                    ...body,
                    "ward": payload.ward
                }
            }
            let response = await httpClient.post(`sentiment/find`, body, {
                params: {
                    ...this.pagination,
                    search: payload.search
                }
            })
            if (response.status === 200 && response.data.code === 0) {
                let data = response.data.data ? response.data.data.map((item) => {
                    for (let i = 0; i < item.media.length; i++) {
                        return {
                            ...item,
                            thumb: item.media[i]
                        }
                    }
                    return {
                        ...item
                    }
                }) : []
                this.entries = data;
                if (this.entries.length == 0) {
                    this.firstPage();
                }
                this.loading = false
            }
            if (response.data.total <= 10) {
                this.pagination = {
                    limit: 10,
                    total: response.data.total,
                    page: 1,
                    skip: 0
                }
            } else {
                this.pagination = {
                    ...this.pagination,
                    total: response.data.total,
                    page: Math.ceil(skip / this.pagination.limit) + 1
                }
            }
        },
        firstPage() {
            let query = {
                ...this.$route.query
            };
            delete (query.page);
            this.$router.replace({
                name: this.$route.name,
                query: query,
            }).catch(err => err);
        },
        strippedHtml(str) {
            let regex = /(<([^>]+)>)/ig;
            str = str.replace(regex, "")
            if (str.length > 200) {
                str = str.substr(0, 200) + '...'
            }
            return str
        },
        replaceBrokenImage(event) {
            event.target.src = require('@/assets/images/no-image.png');
        },
        async restore(entry) {
            await Swal.queue([{
                title: 'Bạn có chắc chắn khôi phục phản ánh này không?',
                confirmButtonText: 'xác nhận',
                confirmButtonColor: '#34c38f',
                showCancelButton: true,
                cancelButtonText: 'Hủy bỏ',
                cancelButtonColor: '#f46a6a',
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    let response = await httpClient.post(`sentiment/archived`, {
                        id: entry.id,
                        archived: false
                    })
                    if (response.data.code === 0) {
                        Swal.insertQueueStep({
                            title: 'Đã khôi phục phản ánh',
                        });
                        await this.getData((this.$route.query.page - 1) * this.pagination.limit, this.$route.query);
                    } else {
                        Swal.insertQueueStep({
                            title: 'Khôi phục phản ánh lỗi',
                        });
                    }
                    return true;
                },
            },]);
        },
        async deleteEntry(entry) {
            await Swal.queue([{
                title: 'Bạn có chắc chắn xóa phản ánh này không?',
                confirmButtonText: 'Xác nhận',
                cancelButtonText: 'Hủy bỏ',
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#f46a6a',
                showCancelButton: true,
                showconfirmButton: true,
                preConfirm: async () => {
                    let response = await httpClient.delete(`sentiment/delete/${entry.id}`, {
                        id: entry.id
                    })
                    console.log('response', response)
                    if (response.data.code === 0) {
                        Swal.insertQueueStep({
                            title: 'Xoá thành công',
                        });
                        await this.getData((this.$route.query.page - 1) * this.pagination.limit, this.$route.query);
                    } else {
                        Swal.insertQueueStep({
                            title: 'Xoá không thành công',
                        });
                    }
                    return true;
                },
            },]);
        },
        getUserData() {
            let userData = localStorage.getItem('token').split('.')[1];
            let userInfo = JSON.parse(Base64.decode(userData));
            this.roleAdmin = userInfo.role;
            this.roleLevel = userInfo.role_level
        }
    },
    created() {
        this.getUserData();
        this.getData((this.$route.query.page - 1) * this.pagination.limit, this.$route.query);
    },
};
</script>

<style scoped>

</style>
